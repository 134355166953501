// src/components/DeliveryStatusCard/DeliveryStatusCard.jsx

import React from 'react';
import './DeliveryStatusCard.css';

function DeliveryStatusCard({
  color = 'gray',             // 卡片背景色，可选：gray / blue / red / pink ...
  isPending = false,          // 是否处于 “Pending” 状态
  expectedDate = '',          // 预计送达日期的字符串，如 "December 23, 2024"
  expectedTime = '',          // 预计送达时间的字符串，如 "9:00pm"
  statusExplanation = '',     // 下方的状态说明文字
  linkText = '',              // 链接按钮的文字，比如 "Subscribe to Tracking Updates"
  onLinkClick,                // 点击链接时的回调，可为空
}) {
  return (
    <div className={`delivery-status-card delivery-status-${color}`}>
      <h3 className="delivery-status-title">Expected Delivery by</h3>

      {isPending ? (
        // 如果是 Pending，则只显示一个「Pending」提示
        <div className="delivery-status-pending">Pending</div>
      ) : (
        // 否则，显示日期 + 时间
        <div className="delivery-status-expected">
          <span className="delivery-status-date">{expectedDate}</span>
          {expectedTime && (
            <span className="delivery-status-time">by {expectedTime}</span>
          )}
        </div>
      )}

      {/* 状态解释 */}
      {statusExplanation && (
        <p className="delivery-status-explanation">{statusExplanation}</p>
      )}

      {/* 链接按钮 */}
      {linkText && (
        <a
          className="delivery-status-link"
          href="#!"
          onClick={(e) => {
            e.preventDefault();
            if (onLinkClick) {
              onLinkClick();
            }
          }}
        >
          {linkText}
        </a>
      )}
    </div>
  );
}

export default DeliveryStatusCard;