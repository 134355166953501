// src/components/SendLetter/SendLetter.jsx
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';             // <-- 新增：导入 react-select
import './SendLetter.css';
import { send as sendApi } from '../../services/api_mail';
import { validateAddress } from '../../services/api_address';
import { countryOptions } from '../../services/countries'; // <-- 新增：导入国家数组

function SendLetter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // 原来的 isDomestic 去掉了，改为 destinationCountry，默认 "US"
  const [destinationCountry, setDestinationCountry] = useState('US');

  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    try {
      // 无论国内/国际，公共字段都放进来
      const requestData = {
        originFullName: data.senderName,
        originAddress1: data.senderStreet,
        originAddress2: data.senderAddress2 || '',
        originCity: data.senderCity,
        originState: data.senderState,
        originZip: data.senderZip,
        type1: 1, // 假设类型1为用户自写
        type3: 1, // 假设类型3为普通信件
        type4: 0, // 预留字段
        destinationFullName: data.recipientName,
        createTime: new Date().toISOString(), // 创建时间

        // 这里最关键：将国家代码写入
        destinationCountry, // <-- ISO 2位代码
      };

      // 如果选择的国家是 "US"，则视为国内
      if (destinationCountry === 'US') {
        requestData.type2 = 1; // 国内
        requestData.destinationAddress1 = data.recipientStreet;
        requestData.destinationAddress2 = data.recipientAddress2 || '';
        requestData.destinationCity = data.recipientCity;
        requestData.destinationState = data.recipientState;
        requestData.destinationZip = data.recipientZip;
      } else {
        // 否则视为国际
        requestData.type2 = 2; // 国际
        // 国际的详细地址直接用一个文本框
        requestData.destinationAddress1 = data.recipientFullAddress;
        requestData.destinationState = data.recipientCountryZip; // 这里存放国家 + 邮编等合并信息
      }

      // 发送请求到后端
      const response = await sendApi(requestData);

      if (response.data.code === 200) {
        // 发送成功
        const trackingNumber = response.data.data.trackingNumber;
        const imb = response.data.data.imb;
        alert(`邮件已成功创建，跟踪编号：${trackingNumber}, imb: ${imb}`);
        navigate('/choose-barcode-option', { state: { trackingNumber, requestData, imb } });
      } else {
        alert(`发送邮件失败：${response.data.msg}`);
      }
    } catch (error) {
      console.log(error);
      alert('发送邮件时发生错误，请稍后重试');
    }
  };

  // 验证寄件人地址（同原来）
  const validateSenderAddress = async () => {
    try {
      const street1 = getValues('senderStreet');
      const street2 = getValues('senderAddress2') || '';
      const city = getValues('senderCity');
      const state = getValues('senderState');
      const zip = getValues('senderZip');

      if (!street1 || !city || !state) {
        alert('请先填写完整的地址信息');
        return;
      }

      const street = street1 + (street2 ? ' ' + street2 : '');
      const addressParams = { street, city, state, zip };
      const response = await validateAddress(addressParams);
      if (response.data.code === 200) {
        const data = JSON.parse(response.data.data);
        if (data.length > 0) {
          const candidate = data[0];
          setValue('senderStreet', candidate.delivery_line_1);
          setValue('senderAddress2', candidate.delivery_line_2 || '');
          setValue('senderCity', candidate.components.city_name);
          setValue('senderState', candidate.components.state_abbreviation);

          // 11位邮编
          const zipCode = candidate.components.zipcode
            + candidate.components.plus4_code
            + candidate.components.delivery_point;
          setValue('senderZip', zipCode);

          alert('地址已标准化');
        } else {
          alert('地址无效，请检查并重试');
        }
      }
    } catch (error) {
      console.error(error);
      alert('地址验证时发生错误，请稍后重试');
    }
  };

  // 验证收件人地址
  const validateRecipientAddress = async () => {
    // 只有美国国内才执行地址验证
    if (destinationCountry !== 'US') {
      return;
    }
    try {
      const street1 = getValues('recipientStreet');
      const street2 = getValues('recipientAddress2') || '';
      const city = getValues('recipientCity');
      const state = getValues('recipientState');
      const zip = getValues('recipientZip');

      if (!street1 || !city || !state) {
        alert('请先填写完整的地址信息');
        return;
      }

      const street = street1 + (street2 ? ' ' + street2 : '');
      const addressParams = { street, city, state, zip };
      const response = await validateAddress(addressParams);
      if (response.data.code === 200) {
        const data = JSON.parse(response.data.data);
        if (data.length > 0) {
          const candidate = data[0];
          setValue('recipientStreet', candidate.delivery_line_1);
          setValue('recipientAddress2', candidate.delivery_line_2 || '');
          setValue('recipientCity', candidate.components.city_name);
          setValue('recipientState', candidate.components.state_abbreviation);

          const zipCode = candidate.components.zipcode
            + candidate.components.plus4_code
            + candidate.components.delivery_point;
          setValue('recipientZip', zipCode);

          alert('地址已标准化');
        } else {
          alert('地址无效，请检查并重试');
        }
      }
    } catch (error) {
      console.error(error);
      alert('地址验证时发生错误，请稍后重试');
    }
  };

  // 根据 destinationCountry 判断是否为 US
  const isDomestic = destinationCountry === 'US';

  return (
    <div className="send-letter">
      <section className="description">
        <h1>{t('sendLetterTitle')}</h1>
        <p>{t('sendLetterDescription')}</p>
      </section>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* 寄件人信息 */}
        <h2>{t('senderInfo')}</h2>
        <div className="form-group">
          <label>{t('fullName')}</label>
          <input type="text" {...register('senderName', { required: true })} />
          {errors.senderName && <p className="error">{t('requiredField')}</p>}
        </div>
        <div className="form-group">
          <label>{t('streetAddress')}</label>
          <input type="text" {...register('senderStreet', { required: true })} />
          {errors.senderStreet && <p className="error">{t('requiredField')}</p>}
        </div>
        <div className="form-group">
          <label>{t('addressLine2')}</label>
          <input type="text" {...register('senderAddress2')} />
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>{t('city')}</label>
            <input type="text" {...register('senderCity', { required: true })} />
            {errors.senderCity && <p className="error">{t('requiredField')}</p>}
          </div>
          <div className="form-group">
            <label>{t('state')}</label>
            <input type="text" {...register('senderState', { required: true })} />
            {errors.senderState && <p className="error">{t('requiredField')}</p>}
          </div>
          <div className="form-group">
            <label>{t('zipCode')}</label>
            <input type="text" {...register('senderZip', { required: true })} />
            {errors.senderZip && <p className="error">{t('requiredField')}</p>}
          </div>
        </div>
        <button type="button" onClick={validateSenderAddress}>
          {t('validateAddress')}
        </button>

        {/* 收件人信息 */}
        <h2>{t('recipientInfo')}</h2>

        {/* 关键修改：国家下拉框（可搜索） */}
        <div className="form-group">
          <label>{t('recipientCountry')}</label>
          <Select
            options={countryOptions} // 你的国家数组
            // 需要一个 value，对应 countryOptions 里的某项
            value={countryOptions.find((item) => item.value === destinationCountry)}
            onChange={(option) => setDestinationCountry(option.value)}
            isSearchable={true} // 启用搜索
          />
        </div>

        {isDomestic ? (
          // 如果是美国，就走国内逻辑
          <>
            <div className="form-group">
              <label>{t('fullName')}</label>
              <input type="text" {...register('recipientName', { required: true })} />
              {errors.recipientName && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('streetAddress')}</label>
              <input type="text" {...register('recipientStreet', { required: true })} />
              {errors.recipientStreet && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('addressLine2')}</label>
              <input type="text" {...register('recipientAddress2')} />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>{t('city')}</label>
                <input type="text" {...register('recipientCity', { required: true })} />
                {errors.recipientCity && <p className="error">{t('requiredField')}</p>}
              </div>
              <div className="form-group">
                <label>{t('state')}</label>
                <input type="text" {...register('recipientState', { required: true })} />
                {errors.recipientState && <p className="error">{t('requiredField')}</p>}
              </div>
              <div className="form-group">
                <label>{t('zipCode')}</label>
                <input type="text" {...register('recipientZip', { required: true })} />
                {errors.recipientZip && <p className="error">{t('requiredField')}</p>}
              </div>
            </div>
            <button type="button" onClick={validateRecipientAddress}>
              {t('validateAddress')}
            </button>
          </>
        ) : (
          // 如果是非美国，则视为国际
          <>
            <div className="form-group">
              <label>{t('fullName')}</label>
              <input type="text" {...register('recipientName', { required: true })} />
              {errors.recipientName && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('fullAddressWithoutCountry')}</label>
              <textarea {...register('recipientFullAddress', { required: true })}></textarea>
              {errors.recipientFullAddress && <p className="error">{t('requiredField')}</p>}
            </div>
            <div className="form-group">
              <label>{t('countryAndZipCode')}</label>
              <input
                type="text"
                {...register('recipientCountryZip', { required: true })}
              />
              {errors.recipientCountryZip && <p className="error">{t('requiredField')}</p>}
            </div>
          </>
        )}

        <button type="submit">{t('nextStep')}</button>
      </form>
    </div>
  );
}

export default SendLetter;